.nav-contents{
  display: block;
}
.hamburger{
  display: none;
}

#hamWindow{
  display: none;
}

@media (max-width:840px){
  .nav-contents{
    display: none;
  }
  .hamburger{
    display: block;
  }
  #hamWindow{
    display: none;
    width: 0px;
    transition: 0.6s;
  }
}