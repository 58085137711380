.container {
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
  line-height: 130%;
  color: #101010;
  /* width: 866px;
  height: 260px; */

}

.heading2 {
  font-weight: 600;
  font-size: 64px;
  line-height: 74px;
  /* or 116% */


  color: #101010;


}

.heading3 {
  font-weight: 600;
  font-size: 64px;
  line-height: 74px;
  /* identical to box height, or 116% */

  text-align: center;

  color: #FFFFFF;
}

.textStyle1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */


  color: #101010;

  opacity: 0.6;
}

.textStyle2 {
  font-weight: 600;
  font-size: 72px;
  line-height: 76px;
  /* identical to box height, or 106% */


  color: #000000;

}

.textStyle3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */


  color: #101010;
}

.textStyle4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */


  color: #101010;

  opacity: 0.6;
}

.textStyle5 {
  font-family: 'Covered By Your Grace';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  /* or 131% */


  color: #1D1D1F;

}

.subheading {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  /* or 122% */


  color: #101010;

}

.subheading2 {

  font-weight: 500;
  font-size: 26px;
  line-height: 34px;

  letter-spacing: 0.02em;

  color: #101010;

  /* width: 959px;
  height: 68px; */
}

.subheading3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;

  letter-spacing: 0.02em;


  color: rgba(16, 16, 16, 0.5);

  /* width: 959px;
  height: 68px; */
}

.subheading4 {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;

  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.6);


  /* width: 959px;
  height: 68px; */
}

.subheading5 {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  /* identical to box height, or 131% */


  color: #FFFFFF;

}

.border1 {
  padding: 10px 10px 10px 30px;
  gap: 10%;

  width: 255px;
  height: 60px;

  background: #FFFFFF;
  border: 1px solid #101010;
  border-radius: 90px;
}

.separator {
  width: 100%;
  height: 1px;

  background: #D9D9D9;
}


@media (max-width: 500px) {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    color: #101010;
  }

  .subheading2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21.1px;

    letter-spacing: 0.02em;

    color: #101010;
  }

  .clicktomore {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 130% */

    letter-spacing: 0.04em;
    text-decoration-line: underline;

    color: #333333;

  }

  .textstyle1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #515151;
  }

  .textStyle2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
  }

  .subheading {
    font-weight: 600;
    font-size: 21px;
    line-height: 130%;
    /* or 27px */

    letter-spacing: 0.02em;

    color: #101010;
  }

  .heading2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
  }

  .heading3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
  }

  .subheading3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #515151;
  }

  .subheading4 {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;

    color: rgba(255, 255, 255, 0.6);
  }

  .subheading5 {
    font-weight: 500;
    font-size: 17px;
    line-height: 34px;
  }

  .textStyle5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;


    color: #1D1D1F;

  }
}