.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 100px;
  line-height: 130%;
  color: #101010;
}

.heading2 {
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 74px;
  /* identical to box height, or 116% */
  color: #101010;

}

.subheading {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  /* or 122% */

  letter-spacing: 0.02em;

  color: #101010;
  /* width: 652px;
  height: 132px; */
}

.subheading2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;

  letter-spacing: 0.02em;

  color: #101010;
}

.clicktomore {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 130% */

  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #333333;

}

.textstyle1 {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */


  color: #515151;

}

.products {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */


  color: #101010;

  opacity: 0.6;
}

.products:active {
  opacity: 100;
}

.separator {
  width: 100%;
  height: 1px;

  background: #D9D9D9;
}

.border1 {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 10px 10px 10px 30px;
  gap: 10%;

  width: 223px;
  height: 60px;

  background: #FFFFFF;
  border: 1px solid #101010;
  border-radius: 90px;
}

@media (max-width: 900px) {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 130%;
    color: #101010;
  }

  .subheading2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;

    letter-spacing: 0.02em;

    color: #101010;
  }

  .subheading3 {
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #515151;
  }
}

@media (max-width: 500px) {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    color: #101010;
  }

  .subheading2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21.1px;

    letter-spacing: 0.02em;

    color: #101010;
  }

  .clicktomore {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 130% */

    letter-spacing: 0.04em;
    text-decoration-line: underline;

    color: #333333;

  }

  .textstyle1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #515151;
  }

  .subheading {
    font-weight: 600;
    font-size: 21px;
    line-height: 130%;
    /* or 27px */

    letter-spacing: 0.02em;

    color: #101010;
  }

  .heading2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
  }

  .subheading3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #515151;
  }
}