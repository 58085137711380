.footer-button-bg {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(32px);
}

.border2 {
  width: 223px;
  height: 60px;

  background: inherit;
  border: 1px solid #ffffff;
  border-radius: 90px;
}