.hideSmall{
  display: none;
}
.hideLarge{
  display: block;
}
@media (min-width:580px) {
  .hideLarge {
    display: none;
  }
  .hideSmall{
    display: block;
  }
}